import React, { ReactNode } from 'react';
import { ShPaymentMethod } from '@shoootin/config';
import { ClientKeys, FrontKeys, ShText } from '@shoootin/translations';

type FrontAndClientKeys = FrontKeys & ClientKeys;

//TODO disable icon until we have correct svg icons to illustrate payment methods
type ShPaymentMethodTileConfig = {
  label: ReactNode;
  // icon: ReactNode;
};

export const ShPaymentMethodTilesConfig: {
  [key in ShPaymentMethod]: ShPaymentMethodTileConfig;
} = {
  CREDIT_CARD: {
    label: (
      <ShText<FrontAndClientKeys>
        message={'enums_PaymentMethods_CREDIT_CARD'}
      />
    ),
    //   icon: <ShCreditCardSvg />,
  },
  CREDIT: {
    label: (
      <ShText<FrontAndClientKeys> message={'enums_PaymentMethods_CREDIT'} />
    ),
    // icon: <FirstLetterSvg forText="x" />,
  },
  UNLIMITED: {
    label: (
      <ShText<FrontAndClientKeys> message={'enums_PaymentMethods_UNLIMITED'} />
    ),
    // icon: <FirstLetterSvg forText="u" />,
  },
  FREE: {
    label: <ShText<FrontAndClientKeys> message={'enums_PaymentMethods_FREE'} />,
    // icon: <FirstLetterSvg forText="f" />,
  },
  INVOICE: {
    label: (
      <ShText<FrontAndClientKeys> message={'enums_PaymentMethods_INVOICE'} />
    ),
    // icon: <FirstLetterSvg forText="i" />,
  },
  ORDER: {
    label: (
      <ShText<FrontAndClientKeys> message={'enums_PaymentMethods_ORDER'} />
    ),
    // icon: <FirstLetterSvg forText="o" />,
  },
};
