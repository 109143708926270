import React, { useMemo } from 'react';
import {
  computeOrderSummary,
  groupOfferOptionsByCategory,
  groupOffersByType,
  sortOptionCategories,
} from 'page/order/utils/orderPageUtils';
import { getRecordKeys } from 'utils/lodashUtils';
import { OrderPageState } from './orderPageContextState';

// Do expensive state-based computations here, using memoization
export const useOrderPageComputed = (state: OrderPageState) => {
  const offersByType = useMemo(() => {
    return groupOffersByType(state.offers || [], state.shootingType);
  }, [state.shootingType, state.offers]);

  const optionsByCategory = useMemo(() => {
    return groupOfferOptionsByCategory(state.options || [], state.shootingType);
  }, [state.shootingType, state.options]);

  const optionCategories = useMemo(() => {
    return sortOptionCategories(getRecordKeys(optionsByCategory));
  }, [optionsByCategory]);

  const orderSummary = useMemo(() => {
    if (state.offers && state.options && state.shootingType) {
      return computeOrderSummary(
        state.offers,
        state.options,
        state.selectedOffers,
        state.shootingType,
        state.offerDiscount,
        state.extraCharge,
      );
    }
  }, [
    state.offers,
    state.options,
    state.selectedOffers,
    state.shootingType,
    state.offerDiscount,
    state.extraCharge,
  ]);

  const canDisplayPortraitTile = state?.country === 'FR';

  return useMemo(() => {
    return {
      offersByType,
      orderSummary,
      optionsByCategory,
      optionCategories,
      canDisplayPortraitTile,
    };
  }, [offersByType, optionsByCategory, orderSummary, optionCategories]);
};

export type OrderPageComputed = ReturnType<typeof useOrderPageComputed>;
