/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { ReactNode } from 'react';
import { ShColors, ShFonts, ShMediaQueries } from '@shoootin/design-tokens';
import { ShCreditCard } from '@shoootin/api';
import { ShInputRadio } from '../../designSystem/primitives/input/ShInputRadio/ShInputRadio';

import AmexSvg from '../svg/logos/Amex';
import DinersSvg from '../svg/logos/Diners';
import VisaSvg from '../svg/logos/VisaSvg';
import MastercardSvg from '../svg/logos/MastercardSvg';
import { ShCreditCardBrand } from '@shoootin/config';
import { ShText } from '@shoootin/translations';
import { ShLinkText } from '../../designSystem/primitives/text/ShLinkText/ShLinkText';

export const ShCreditCardBrandVisual: Record<
  ShCreditCardBrand,
  ReactNode | undefined
> = {
  'American Express': <AmexSvg width={72} />,
  amex: <AmexSvg width={72} />,
  'Diners Club': <DinersSvg width={72} />,
  Discover: undefined,
  JCB: undefined,
  MasterCard: <MastercardSvg width={72} />,
  mastercard: <MastercardSvg width={72} />,
  UnionPay: undefined,
  Visa: <VisaSvg width={72} />,
  visa: <VisaSvg width={72} />,
  Unknown: undefined,
};

const CreditCardInfo = ({ card }: { card: ShCreditCard }) => {
  const commonStyles = {
    marginTop: 4,
    [ShMediaQueries.ShMobileOnly]: { fontSize: 14 },
  };
  return (
    <React.Fragment>
      <div css={commonStyles}>···· {card.last4}</div>
      <div
        css={{
          fontSize: 20,
          fontFamily: ShFonts.secondary,
          ...commonStyles,
        }}
      >
        {card.brand}
      </div>
      <div css={commonStyles}>
        {card.expMonth} / {card.expYear}
      </div>
    </React.Fragment>
  );
};

export const ShSavedCreditCard = ({
  selected = false,
  onSelect,
  card,
  onDelete,
}: {
  selected?: boolean;
  onSelect?: () => void;
  card: ShCreditCard;
  onDelete?: () => void;
}) => {
  return (
    <div
      css={{
        borderRadius: 3,
        padding: 25,
        paddingBottom: 15,
        border: `2px solid ${ShColors.pattern}`,
        [ShMediaQueries.ShMobileOnly]: {
          padding: 15,
          borderLeft: `3px solid ${ShColors.base}`,
        },
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          paddingBottom: 10,
        }}
      >
        {onSelect && (
          <span
            css={{
              marginRight: 20,
              [ShMediaQueries.ShMobileOnly]: {
                order: 2,
                marginRight: 0,
              },
            }}
          >
            <ShInputRadio value={selected} onChange={onSelect} />
          </span>
        )}
        <span
          css={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            justifyContent: 'center',
            marginBottom: -4,
            [ShMediaQueries.ShMobileOnly]: {
              order: 1,
              marginLeft: 15,
            },
          }}
        >
          <CreditCardInfo card={card} />
        </span>
        {ShCreditCardBrandVisual[card.brand] && (
          <span
            css={{
              backgroundColor: 'transparent',
              borderRadius: 3,
              boxShadow: `0 0 1rem 0 rgba(0,0,0,0.1)`,
              padding: 15,
              margin: '-4px 0',
              transition:
                'background-color 150ms cubic-bezier(0.4, 0.0, 0.2, 1)',
              width: 100,
              height: 100,
              display: 'flex',
              alignItems: 'center',
              [ShMediaQueries.ShMobileOnly]: {
                width: 60,
                height: 60,
                padding: 10,
                order: 0,
              },
            }}
          >
            {ShCreditCardBrandVisual[card.brand]}
          </span>
        )}
      </div>
      {onDelete && (
        <div
          css={{
            textAlign: 'right',
            paddingTop: 10,
            fontSize: '80%',
          }}
        >
          <span onClick={onDelete}>
            <ShLinkText bold={false}>
              <ShText message={'client_accountPageBilling_removeCreditCard'} />
            </ShLinkText>
          </span>
        </div>
      )}
    </div>
  );
};
