/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import {
  CardCvcElement,
  CardElement,
  CardExpiryElement,
  CardNumberElement,
  ReactStripeElements,
} from 'react-stripe-elements';

import {
  ShCheckboxField,
  ShCol,
  ShRow,
  ShStripeSvg,
  ShStyles,
} from '@shoootin/components-web';
import { ShText, useShTranslate } from '@shoootin/translations';
import { ShColors, ShMediaQueries } from '@shoootin/design-tokens';
import {
  getPaymentMethodsMessage,
  ShOrderStepPayment,
} from './ShPaymentMethodTranslations';
import { ShInputError } from '../../designSystem/primitives/input/utils/ShInputError/ShInputError';
import { ShHelpModalButton } from '../help/ShHelpModalButton';
import { ShLinkText } from '../../designSystem/primitives/text/ShLinkText/ShLinkText';

const DefaultCardElementStyles: ReactStripeElements.ElementsOptions['style'] = {
  base: {
    fontSize: '16px',
    color: 'black',
    letterSpacing: '0.025em',
    fontFamily: 'Montserrat, Verdana, sans-serif',
    lineHeight: '60px',
    paddingLeft: '10px',
    '::placeholder': {
      color: ShColors.whiteD,
    },
    ':focus': {
      // @ts-ignore
      '::placeholder': {
        color: ShColors.whiteD,
      },
    },
  },
  invalid: {
    color: ShColors.error,
  },
};

const fieldInputStyles = {
  padding: '0px !important',
  paddingLeft: '10px !important',
  borderRadius: 3,
  backgroundColor: ShColors.whiteD,
  border: ShColors.whiteD,
  color: ShColors.black,
  // lineHeight: 1.2,
};

export const ShCreditCardInput = ({
  setSaveCreditCard,
  saveCreditCard,
  paymentErrors,
  isDev = false,
  canSaveCreditCard = true,
  ...cardElementProps
}: {
  setSaveCreditCard?: (saveCreditCard: boolean) => void;
  saveCreditCard: boolean;
  paymentErrors?: string;
  isDev?: boolean;
  canSaveCreditCard?: boolean;
} & React.ComponentProps<typeof CardElement>) => {
  const t = useShTranslate();
  console.log('cardelement', cardElementProps);

  return (
    <div
      css={{
        padding: 25,
        borderRadius: 3,
        border: `2px solid ${ShColors.pattern}`,
      }}
    >
      <div css={{ marginTop: 10, width: '100%', display: 'flex' }}>
        <span css={{ flexGrow: 1 }}>
          <label css={ShStyles.label}>
            <ShText message={ShOrderStepPayment.paymentMethodYourCreditCard} />
          </label>
          <div className="field-control  large">
            <div className="field-input" css={fieldInputStyles}>
              <CardNumberElement
                className={'stripe-react'}
                /* StripeElement already has styling in old app that we don't want to apply here */
                {...cardElementProps}
                style={
                  cardElementProps.style
                    ? {
                        ...DefaultCardElementStyles,
                        ...cardElementProps.style,
                      }
                    : DefaultCardElementStyles
                }
              />
            </div>
          </div>
        </span>
      </div>
      <ShRow className="field-row space" css={{ marginTop: 20 }}>
        <ShCol xs={60} md={30}>
          <label css={ShStyles.label}>
            <ShText
              message={ShOrderStepPayment.paymentMethodYourCreditCardExpiration}
            />
          </label>
          <div className="field-control  large">
            <div className="field-input" css={fieldInputStyles}>
              <CardExpiryElement
                className={'stripe-react'}
                /* StripeElement already has styling in old app that we don't want to apply here */
                {...cardElementProps}
                style={
                  cardElementProps.style
                    ? {
                        ...DefaultCardElementStyles,
                        ...cardElementProps.style,
                      }
                    : DefaultCardElementStyles
                }
              />
            </div>
          </div>
        </ShCol>
        <ShCol
          xs={60}
          md={30}
          css={{ [ShMediaQueries.ShMobileOnly]: { marginTop: 20 } }}
        >
          <label css={ShStyles.label}>
            <ShText
              message={ShOrderStepPayment.paymentMethodYourCreditCardCVC}
            />
          </label>
          <div className="field-control  large">
            <div className="field-input" css={fieldInputStyles}>
              <CardCvcElement
                className={'stripe-react'}
                /* StripeElement already has styling in old app that we don't want to apply here */
                {...cardElementProps}
                style={
                  cardElementProps.style
                    ? {
                        ...DefaultCardElementStyles,
                        ...cardElementProps.style,
                      }
                    : DefaultCardElementStyles
                }
              />
            </div>
          </div>
        </ShCol>
      </ShRow>
      <div className="field-row space" css={{ marginTop: 20 }}>
        {paymentErrors && <ShInputError>{paymentErrors}</ShInputError>}
      </div>
      {setSaveCreditCard && canSaveCreditCard && (
        <div className="field-row gap" css={{ marginTop: 40 }}>
          <ShCheckboxField
            label={t(
              getPaymentMethodsMessage(
                'front_order_step_payment_saveCreditCard',
              ),
            )}
            bold={true}
            value={saveCreditCard}
            onChange={setSaveCreditCard}
          />
        </div>
      )}
      {isDev && <TestCards />}
      <div
        className="form-help"
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          marginTop: 25,
          paddingTop: 25,
          borderTop: `1px solid ${ShColors.whiteD}`,
        }}
      >
        <div css={{ [ShMediaQueries.ShMobileOnly]: { flexBasis: '90%' } }}>
          <span css={{ marginBottom: 10, display: 'block' }}>
            <ShStripeSvg width={119} />
          </span>
          <ShText message={ShOrderStepPayment.paymentMethodStripeInfos} />
        </div>
        <ShHelpModalButton modalName="paymentStripe" />
      </div>
    </div>
  );
};

const TestCards = () => {
  const [showTestCards, setShowTestCards] = React.useState<boolean>(false);

  if (!showTestCards) {
    return (
      <small onClick={() => setShowTestCards(true)}>
        <ShLinkText bold={false}>show test cards</ShLinkText>
      </small>
    );
  }

  return (
    <div>
      <small onClick={() => setShowTestCards(false)}>
        <ShLinkText bold={false}>hide test cards</ShLinkText>
      </small>
      <table className="alternate" css={{ fontSize: 12 }}>
        <thead>
          <tr>
            <th>Number</th>
            <th>Authentication</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="card-number">
              4000
              <span />
              0000
              <span />
              0000
              <span />
              0002
            </td>
            <td>None</td>
            <td>
              Charge is declined with a <code>card_declined</code> code.
            </td>
          </tr>
          <tr>
            <td className="card-number">
              4000
              <span />
              0025
              <span />
              0000
              <span />
              3155
            </td>
            <td>Required on setup or first transaction</td>
            <td>
              This test card requires authentication for{' '}
              <a href="/docs/payments/payment-intents/web">one-time payments</a>
              . However, if you set up this card using the{' '}
              <a href="/docs/payments/cards/saving-cards-without-payment">
                Setup Intents API
              </a>{' '}
              and use the saved card for subsequent payments, no further
              authentication is needed.
            </td>
          </tr>
          <tr>
            <td className="card-number">
              4000
              <span />
              0027
              <span />
              6000
              <span />
              3184
            </td>
            <td>Required</td>
            <td>This test card requires authentication on all transactions.</td>
          </tr>
          <tr>
            <td className="card-number">
              4000
              <span />
              0082
              <span />
              6000
              <span />
              3178
            </td>
            <td>Required</td>
            <td>
              This test card requires authentication, but payments will be
              declined with an <code>insufficient_funds</code> failure code
              after successful authentication.
            </td>
          </tr>
          <tr>
            <td className="card-number">
              4000
              <span />
              0000
              <span />
              0000
              <span />
              3055
            </td>
            <td>Supported</td>
            <td>
              This test card supports authentication via 3D Secure 2, but does
              not require it. Payments using this card do not require additional
              authentication in test mode unless your{' '}
              <a href="/docs/payments/3d-secure#three-ds-radar">
                test mode Radar rules
              </a>{' '}
              request authentication.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
