import React, { useEffect, useState } from 'react';
import { AppSection } from 'primitives/appSection';
import { H3, H4 } from 'primitives/appTitle';

// @ts-ignore
import { device } from 'helpers/helpers';
import {
  ShButtonAsync,
  ShButtonGroup,
  ShCol,
  ShDatePicker,
  ShFormikNumberField,
  ShFormikTextAreaField,
  ShFormikTextField,
  ShHelpModalDiv,
  ShInputFile,
  ShInputFileUploadResult,
  ShIntercomArticles,
  ShPrice,
  ShRow,
  ShTitle,
} from '@shoootin/components-web';
import { useCurrentClientUser } from '../../../../../../state/currentUserState';
import { range } from 'lodash';
import { ShColors } from '@shoootin/design-tokens';
import { getFrontMessage } from '../../../../../../appMessageDefinitions';
import {
  OrderPortraitDTO,
  OrderPortraitValues,
  ShPortraitsLabels,
  ShPortraitStyle,
  ShPortraitStyles,
} from './stepOrderPortraitDTO';
import {
  getPortraitPrice,
  getProposedMinutes,
  getRecommendedDuration,
  minutesToHour,
} from './stepOrderPortraitUtils';
import { OrderAPI } from '../../../../../../appAPI';
import classnames from 'classnames';
import { Sticky, StickyContainer } from 'react-sticky';
import { getStickyStyle } from '../../../../utils/orderPageUtils';
import {
  ShText,
  useShIntlLocale,
  useShTranslate,
} from '@shoootin/translations';
import { OrderStepOrder } from '../../../../orderPageTranslations';
import { CartCustom, CartDuration } from '../../../../components/cartProgress';
import { ShApiUtils } from '@shoootin/api';
import { Formik, useFormikContext } from 'formik';
import { OrderAddress } from '../../../../context/orderPageContextState';
import { ShCountry } from '@shoootin/config';

import { nextWorkingDay, ShMomentType } from '@shoootin/utils';

const Cart = ({
  submitForm,
  nbOfPeople,
  nbOfPortraitPerPeople,
  minutes,
  portraitPrice,
  canValidate,
}: {
  submitForm: any;
  nbOfPeople: number;
  nbOfPortraitPerPeople: number;
  minutes: number;
  portraitPrice: number;
  canValidate: boolean;
}) => {
  return (
    <div id="cart" className="cart">
      <H4 className="cart__header">
        <ShText message={OrderStepOrder.cartTitle} />
      </H4>
      <div className="cart__panel">
        <article className="cart-item">
          <CartCustom label={'Nombre de portraits'} value={nbOfPeople} />
          <CartCustom
            label={'Portraits par personne'}
            value={nbOfPortraitPerPeople}
          />
          <CartDuration totalTime={minutes} />
        </article>
        <footer className="cart__footer">
          <p className="cart__total" css={{ marginBottom: 10 }}>
            <span className="cart__label">
              <ShText
                message={getFrontMessage('common_order_totalPriceWithoutTax')}
              />
            </span>
            <span className="cart__price">
              <b>
                <ShPrice price={portraitPrice} currency={'EUR'} />
              </b>
            </span>
          </p>
          <ShButtonAsync
            size={'l'}
            onClick={submitForm}
            disabled={!canValidate}
            width={'100%'}
          >
            <ShText message={getFrontMessage('common_actions_validate')} />
          </ShButtonAsync>
        </footer>
      </div>
    </div>
  );
};

const CartSticky = ({
  sticky,
  canBeSticky,
  ...props
}: React.ComponentProps<typeof Cart> & {
  sticky: Parameters<typeof getStickyStyle>[0];
  canBeSticky: boolean;
}) => (
  <Sticky disableCompensation>
    {() => {
      const stickyStyle = getStickyStyle(sticky);
      return (
        <div style={canBeSticky ? stickyStyle : {}}>
          <Cart {...props} />
        </div>
      );
    }}
  </Sticky>
);

const StepOrderPortraitFormik = ({ success }: { success: boolean }) => {
  const form = useFormikContext<OrderPortraitDTO>();
  console.debug('form', form);
  const currentUser = useCurrentClientUser();
  const locale = useShIntlLocale();

  const recommendedDuration = getRecommendedDuration(form.values.nbOfPeople);
  const proposedMinutes = getProposedMinutes(recommendedDuration);

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(form.values.nbOfPeople)) {
      form.setFieldValue('nbOfPeople', 0);
    } else {
      const nbOfPeople = Math.min(100, Math.max(0, form.values.nbOfPeople));
      form.setFieldValue('nbOfPeople', nbOfPeople);
    }
    form.setFieldValue(
      'portraitPrice',
      getPortraitPrice(
        form.values.nbOfPeople,
        form.values.nbOfPortraitPerPeople,
        form.values.minutes,
      ),
    );
  }, [
    form.values.nbOfPeople,
    form.values.nbOfPortraitPerPeople,
    form.values.minutes,
  ]);

  useEffect(() => {
    form.setFieldValue('minutes', recommendedDuration);
  }, [recommendedDuration]);

  const tr = useShTranslate();

  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const canValidate = form.values.nbOfPeople > 0 && !fileLoading;

  const portraitStyleGroupItems = ShPortraitStyles.map(
    (style: ShPortraitStyle) => ({
      selected: style === form.values.portraitStyle,
      onClick: () => {
        if (style === form.values.portraitStyle) {
          form.setFieldValue('portraitStyle', undefined);
        } else {
          form.setFieldValue('portraitStyle', style);
        }
      },
      children: <span>{ShPortraitsLabels[style]}</span>,
    }),
  );

  return (
    <StickyContainer>
      <AppSection className="left form-order-prestations">
        <div className="form-wrapper" id="form-order-offers-portrait">
          <div className="form-order-prestations__content form-details">
            <div>
              <H3
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                Votre offre portrait
                <ShIntercomArticles id={7842101}>
                  <ShHelpModalDiv />
                </ShIntercomArticles>
              </H3>
            </div>
            <div
              css={{
                border: `2px solid ${ShColors.pattern}`,
                borderRadius: 3,
                padding: 20,
              }}
            >
              <div>
                {!success && (
                  <div
                    id="portrait-title"
                    css={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 40,
                      marginBottom: 20,
                    }}
                  >
                    <div>
                      <div css={{ marginBottom: 10 }}>
                        <ShTitle size={5}>Nombre de personnes</ShTitle>
                      </div>
                      <div css={{ width: 80 }}>
                        <ShFormikNumberField fieldName="nbOfPeople" />
                      </div>
                    </div>
                    <div>
                      <div css={{ marginBottom: 10 }}>
                        <ShTitle size={5}>
                          Nombre de portrait par personne
                        </ShTitle>
                      </div>
                      <div>
                        <ShButtonGroup
                          items={range(1, 6).map((nb: number) => ({
                            selected: nb === form.values.nbOfPortraitPerPeople,
                            onClick: () => {
                              form.setFieldValue('nbOfPortraitPerPeople', nb);
                            },
                            children: <span>{nb}</span>,
                          }))}
                        />
                      </div>
                    </div>
                    <div>
                      <div css={{ marginBottom: 10 }}>
                        <ShTitle size={5}>Durée</ShTitle>
                      </div>
                      <div css={{ marginBottom: 10 }}>
                        <ShButtonGroup
                          items={proposedMinutes.map((proposedMinute) => ({
                            selected: proposedMinute === form.values.minutes,
                            onClick: () =>
                              form.setFieldValue('minutes', proposedMinute),
                            children: (
                              <span>{minutesToHour(proposedMinute)}</span>
                            ),
                          }))}
                        />
                      </div>

                      <div>
                        <small>
                          Durée conseillée :{' '}
                          <b>
                            {minutesToHour(
                              getRecommendedDuration(form.values.nbOfPeople),
                            )}
                          </b>
                        </small>
                      </div>
                    </div>
                    <div>
                      <div css={{ marginBottom: 10 }}>
                        <ShTitle size={5}>Style de portrait</ShTitle>
                      </div>
                      <div>
                        <ShButtonGroup items={portraitStyleGroupItems} />
                      </div>
                    </div>
                    <div>
                      <div css={{ marginBottom: 10 }}>
                        <ShTitle size={5}>Votre brief</ShTitle>
                      </div>
                      <ShFormikTextAreaField<OrderPortraitDTO>
                        fieldName="comment"
                        label={''}
                        placeholder={
                          "Donnez nous plus d'informations concernant votre demande"
                        }
                        rows={5}
                      />
                    </div>
                    <div css={{ maxWidth: 400 }}>
                      <div css={{ marginBottom: 10 }}>
                        <ShTitle size={5}>La date souhaitée</ShTitle>
                      </div>
                      <ShDatePicker
                        date={form.values.date}
                        onDateChange={(date: ShMomentType) => {
                          form.setFieldValue('date', date);
                        }}
                        isDayBlocked={undefined}
                        firstAvailableDate={nextWorkingDay()}
                      />
                    </div>
                    <div>
                      <div css={{ marginBottom: 10 }}>
                        <ShTitle size={5}>Votre guideline</ShTitle>
                      </div>
                      <div css={{ marginBottom: 10 }}>
                        Chargez votre guideline ou des exemples de portraits
                        pour que nos équipes puissent comprendre votre besoin.
                      </div>
                      <ShInputFile
                        onFileSelect={() => {
                          setFileLoading(true);
                        }}
                        onFileUploaded={(
                          uploadedFile: ShInputFileUploadResult,
                        ) => {
                          form.setFieldValue('file', uploadedFile);
                          setFileLoading(false);
                        }}
                        autoUpload={true}
                        onFileDelete={() => {
                          form.setFieldValue('file', undefined);
                          setFileLoading(false);
                        }}
                      >
                        Charger ma guideline
                      </ShInputFile>
                    </div>
                    <div>
                      {!currentUser && (
                        <div css={{ marginBottom: 20 }}>
                          <ShTitle size={5}>Vos informations</ShTitle>
                          <ShRow className="field-row wrap-s">
                            <ShCol xs={60} md={30}>
                              <ShFormikTextField<OrderPortraitDTO>
                                fieldName="firstName"
                                placeholder={tr(
                                  getFrontMessage(
                                    'form_userInfos_firstName_PlaceHolder',
                                  ),
                                )}
                                required
                              />
                            </ShCol>
                            <ShCol xs={60} md={30}>
                              <ShFormikTextField<OrderPortraitDTO>
                                fieldName="lastName"
                                placeholder={tr(
                                  getFrontMessage(
                                    'form_userInfos_lastName_PlaceHolder',
                                  ),
                                )}
                                required
                              />
                            </ShCol>
                          </ShRow>
                          <ShRow className="field-row wrap-s">
                            <ShCol xs={60} md={35}>
                              <ShFormikTextField<OrderPortraitDTO>
                                fieldName="email"
                                placeholder={tr(
                                  getFrontMessage(
                                    'form_userInfos_email_PlaceHolder',
                                  ),
                                )}
                                required
                              />
                            </ShCol>
                            <ShCol xs={60} md={25}>
                              <ShFormikTextField<OrderPortraitDTO>
                                fieldName="mobilePhone"
                                placeholder={tr(
                                  getFrontMessage(
                                    'form_userInfos_mobilePhone_PlaceHolder',
                                  ),
                                )}
                                required
                              />
                            </ShCol>
                          </ShRow>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {success && (
                  <div
                    id="portrait-title"
                    css={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 40,
                      marginBottom: 20,
                    }}
                  >
                    <ShTitle size={5}>Demande envoyée</ShTitle>
                    <div>
                      <div css={{ marginBottom: 10 }}>
                        Merci pour votre demande de reportage portraits.
                      </div>
                      <div>
                        Nos équipes ont bien reçu les instructions et vont
                        revenir vers vous dans un délai de 48 heures maximum
                        pour confirmer la seance.
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {!success && (
            <div
              className={classnames('form-cart form-cart-order', {
                opened: true, // this seems to be used to open the mobile sticky footer...
              })}
            >
              <CartSticky
                sticky={{
                  wrapper: '#form-order-offers-portrait',
                  wrapperFirstElement: '#portrait-title',
                  header: '.form-breadcrumbs',
                  cart: '#cart',
                }}
                canBeSticky={!device().isMobile && !device().isTablet}
                canValidate={canValidate}
                submitForm={form.submitForm}
                nbOfPeople={form.values.nbOfPeople}
                nbOfPortraitPerPeople={form.values.nbOfPortraitPerPeople}
                minutes={form.values.minutes}
                portraitPrice={form.values.portraitPrice}
              />
            </div>
          )}
        </div>
      </AppSection>
    </StickyContainer>
  );
};

const StepOrderPortraitForm = ({
  address,
  country,
}: {
  address: OrderAddress;
  country: ShCountry;
}) => {
  const initialValuesAddress = {
    address: address!.address,
    latitude: address!.latitude,
    longitude: address!.longitude,
    country,
  };

  const initialValues = {
    ...OrderPortraitValues,
    address: initialValuesAddress,
    date: nextWorkingDay(),
  };
  const [success, setSuccess] = useState<boolean>(false);

  return (
    <Formik<OrderPortraitDTO>
      initialValues={initialValues}
      onSubmit={async (values, { setErrors }) => {
        try {
          // cast is safe, because values have been validated locally
          await OrderAPI.validatePortrait(values as OrderPortraitDTO);
          setSuccess(true);
        } catch (e) {
          setErrors(ShApiUtils.getApiFormErrors(e));
          setSuccess(false);
          throw e;
        }
      }}
    >
      <StepOrderPortraitFormik success={success} />
    </Formik>
  );
};

export default StepOrderPortraitForm;
