import { ShInputFileUploadResult } from '@shoootin/components-web';
import { minTimeOnSite } from './stepOrderPortraitUtils';
import { OrderAddressDTO } from '../../../../../../appAPITypes';
import { nextWorkingDay, ShMomentType } from '@shoootin/utils';

export const ShPortraitStyles = ['fullBody', 'american', 'tight'] as const;
export type ShPortraitStyle = typeof ShPortraitStyles[number];
export const ShPortraitsLabels: Record<ShPortraitStyle, string> = {
  fullBody: 'Plain pied',
  american: 'Américain',
  tight: 'Plan serré',
};

export type OrderPortraitDTO = {
  address: OrderAddressDTO;
  nbOfPeople: number;
  nbOfPortraitPerPeople: number;
  minutes: number;
  portraitPrice: number;
  comment: string;
  portraitStyle?: ShPortraitStyle;
  file?: ShInputFileUploadResult;
  email?: string;
  lastName?: string;
  firstName?: string;
  mobilePhone?: string;
  date: ShMomentType;
};

export const OrderPortraitValues: OrderPortraitDTO = {
  address: {
    country: 'FR',
    address: '',
    latitude: 0,
    longitude: 0,
  },
  nbOfPeople: 1,
  nbOfPortraitPerPeople: 1,
  minutes: minTimeOnSite,
  portraitPrice: 0,
  comment: '',
  portraitStyle: undefined,
  email: '',
  lastName: '',
  firstName: '',
  mobilePhone: '',
  date: nextWorkingDay(),
};
