import { defineMessages } from 'react-intl';
import {
  ShIntlMessageDefinition,
  FrontKeys,
  ClientKeys,
  getShMessage,
} from '@shoootin/translations';

export type PaymentMethodsTargets = FrontKeys & ClientKeys;

export const getPaymentMethodsMessage = (
  key: PaymentMethodsTargets,
  defaultMessage?: string,
): ShIntlMessageDefinition => getShMessage(key, defaultMessage);

const prefix = 'front_order';
const step_prefix = `${prefix}_step`;
const step_prefix_payment = `${step_prefix}_payment`;

export const ShOrderStepPayment = defineMessages({
  paymentMethodYourCreditCard: {
    id: `${step_prefix_payment}_paymentMethodYourCreditCard`,
    defaultMessage: 'Votre carte de crédit',
  },
  paymentMethodYourCreditCardExpiration: {
    id: `${step_prefix_payment}_paymentMethodYourCreditCardExpiration`,
    defaultMessage: 'Expiration',
  },
  paymentMethodYourCreditCardCVC: {
    id: `${step_prefix_payment}_paymentMethodYourCreditCardCVC`,
    defaultMessage: 'CVC',
  },
  saveCreditCard: {
    id: `${step_prefix_payment}_saveCreditCard`,
    defaultMessage: 'Enregistrer votre carte',
  },
  paymentMethodStripeInfos: {
    id: `${step_prefix_payment}_paymentMethodStripeInfos`,
    defaultMessage:
      'Besoin d’en savoir plus sur la méthode de paiement Stripe ?',
  },
  paymentMethodTitle: {
    id: `${step_prefix_payment}_paymentMethodTitle`,
    defaultMessage: 'Moyen de paiement',
  },
  paymentMethodIntro: {
    id: `${step_prefix_payment}_paymentMethodIntro`,
    defaultMessage: 'Choisissez votre moyen de paiement préféré.',
  },
  paymentMethodCreditDescription: {
    id: `${step_prefix_payment}_paymentMethodCreditDescription`,
    defaultMessage: 'Il vous reste {credit} de crédit sur votre compte.',
  },
  paymentMethodUnlimitedDescription: {
    id: `${step_prefix_payment}_paymentMethodUnlimitedDescription`,
    defaultMessage:
        "Vous avez {current} d'encours de consommation à la carte avec une limite de {limit}.",
  },
  paymentMethodSavedCreditCardTitle: {
    id: `${step_prefix_payment}_paymentMethodSavedCreditCardTitle`,
    defaultMessage: 'Choisir une carte',
  },
  paymentMethodSavedACHTitle: {
    id: `${step_prefix_payment}_paymentMethodSavedACHTitle`,
    defaultMessage: 'Choisir un compte bancaire / ACH',
  },
});
